<template lang="pug">
form(@submit.prevent="onSubmit")                                                                                                                                                                                                                                                                                                                                                       
  AppCard
    h1 {{$t('contract.contact_form.form_name')}}
    AppFormInput(type="text" :label="$t('contract.contact_form.name')" name="name" required)
    .row
      AppFormInput(type="text" :label="$t('contract.contact_form.email')" name="email_from")
      .row
        AppFormInput(type="text" :label="$t('contract.contact_form.phone')" name="phone" required)
        AppFormInput(type="text" :label="$t('contract.contact_form.mobile')" name="mobile")
    h1 {{$t('contract.address_form.form_name')}}
    .row
      AppFormInput(type="text" :label="$t('contract.address_form.street')" name="calle_servicio")
      .row
        AppFormInput(type="text" :label="$t('contract.address_form.ext')" name="num_ext_servicio")
        AppFormInput(type="text" :label="$t('contract.address_form.int')" name="num_int_servicio")
    .row
      AppFormInput(type="select" :options="cityOptions" :label="$t('contract.address_form.city')" name="ciudad_servicio")
      AppFormInput(type="text" :label="$t('contract.address_form.zip_code')" name="cp_servicio")
    AppFormInput(type="select" label="Asentamiento" :options="localitiesOptions" name="asentamiento_id")
    AppFormInput(type="textarea" label="Información Adicional" name="description")
    .row
      AppCheckbox(:label="$t('contract.contact_form.invoice_check')"  v-model="checkvalue")
    .row(v-show="checkvalue")
      AppFormInput(type="text" :label="$t('contract.contact_form.business_name')" name="razon_social")
      AppFormInput(type="text" :label="$t('contract.contact_form.identification')" name="rfc_sat")
    AppButton.mb-0(:loading="loading" :disabled="loading" :label="$t('common.send')")
</template>

<script>
import AppCard from "@/components/AppCard";
import AppFormInput from "@/components/Form/AppFormInput";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppButton from "@/components/AppButton";
import { computed, onMounted, ref } from "@vue/runtime-core";
import { store } from "@/store";
import { axios } from "@/utils/axios";
export default {
  components: { AppCard, AppFormInput, AppCheckbox, AppButton },
  setup() {
    const listLocalities = ref([]);
    const checkvalue = ref(false);
    const localitiesOptions = computed(() =>
      listLocalities.value.map((el) => ({ value: el.id, label: el.name }))
    );
    const loading = computed(() => store.APP_LOADING);

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        const { data } = await axios.get("lead/asentamientos");
        listLocalities.value = data.payload;
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });

    const cityOptions = [
      { value: "Bacalar", label: "Bacalar" },
      { value: "Benito Juárez", label: "Benito Juárez" },
      { value: "Cozumel", label: "Cozumel" },
      { value: "Felipe Carrillo Puerto", label: "Felipe Carrillo Puerto" },
      { value: "Isla Mujeres", label: "Isla Mujeres" },
      { value: "José María Morelos", label: "José María Morelos" },
      { value: "Lázaro Cárdenas", label: "Lázaro Cárdenas" },
      { value: "Othón P. Blanco", label: "Othón P. Blanco" },
      { value: "Puerto Morelos", label: "Puerto Morelos" },
      { value: "Solidaridad", label: "Solidaridad" },
      { value: "Tulum", label: "Tulum" },
    ];

    const locationOptions = [
      { value: "tulum", label: "Tulum" },
      { value: "cancun", label: "Cancún" },
      { value: "puerto morelos", label: "Puerto Morelos" },
      { value: "puerto aventuras", label: "Puerto Aventuras" },
      { value: "playa del carmen", label: "Playa del Carmen" },
      { value: "isla mujeres", label: "Isla Mujeres" },
      { value: "otro", label: "Otro" },
    ];

    const onSubmit = async (e) => {
      try {
        store.APP_LOADING = true;
        const formData = {};
        const body = new FormData(e.target);
        body.forEach((value, key) => (formData[key] = value));

        //Validar RFC y Razon Social
        if (formData.razon_social == "") {
          formData.razon_social = "AL PÚBLICO GENERAL";
        }

        if (formData.rfc_sat == "") {
          formData.rfc_sat = "XAXX010101000";
        }

        const { ["data"]: partner_id } = await axios.post("lead/contact", {
          name: formData.name,
          display_name: formData.name,
          phone: formData.phone,
          mobile: formData.mobile,
          razon_social: formData.razon_social,
          vat: formData.rfc_sat,
          tipo_cliente: "RESIDENCIAL",
          team_id: 1,
          country_id: 156,
          cliente: true,
          active: true,
        });
        let asentamiento = listLocalities.value.find(
          (el) => el.id == formData.asentamiento_id
        );
        formData.partner_id = partner_id.payload;
        formData.asentamiento_servicio = asentamiento.name;
        formData.tipo_asentamiento_id = asentamiento.tipo_asentamiento_id[0];
        formData.tipo_asentamiento_servicio =
          asentamiento.tipo_asentamiento_id[1];
        const { data } = await axios.post("lead/", {
          ...formData,
          location_of_service: "otro",
          stage_id: 1, //DEPENDE DE COBERTURA
          type: "lead", //DEPENDE DE COBERTURA
          partner_name: formData.name,
          contact_name: formData.name,
          type_service: "RESIDENCIAL",
          type_tecno_plan_servicio: "FIBRA",
          tarifa_id: 9, //JUAN ME LO MANDA
          plan_servicio: 234, //JUAN ME LO MANDA
          precio_plan: 799.0, //JUAN ME LO MANDA
          sin_costo_de_instalacion: false, //JUAN ME LO MANDA
          monto_aux_costo_instalacion: 2500.0, //JUAN ME LO MANDA
          coordenadas_contact: "https://www.google.com/maps/place/", //JUAN ME LO MANDA
          latitud_contact: "21.1595383", //JUAN ME LO MANDA
          longitud_contact: "-86.8233023", //JUAN ME LO MANDA
          network_coverage: true, //JUAN ME LO MANDA
          municipio_servicio_select: formData.ciudad_servicio,
          estado_servicio: "QUINTANA ROO",
          municipio_servicio: formData.ciudad_servicio,
        });
        await axios.post(`lead/${data.payload}`, {
          id: data.payload,
          stage_id: 4,
          type: "opportunity",
        });
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    };

    return {
      listLocalities,
      localitiesOptions,
      checkvalue,
      loading,
      onSubmit,
      cityOptions,
      locationOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.row {
  @screen md {
    @apply grid;
    @apply gap-4;
    @apply grid-flow-col;
    @apply auto-cols-fr;
  }
  &.margin {
    @apply mt-2;
    @apply mb-5;
  }
}
form {
  @apply mx-auto;
  @screen lg {
    @apply w-3/4;
  }
  @screen xl {
    @apply w-1/2;
  }
}
</style>
